import gql from 'graphql-tag';

// TODO: Add "$taxonomies: [Str]" to search once it becomes available in _searchStats and search.
export const SEARCH_ARTICLES = gql`
  query SearchArticles($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Article {
          id
          oid
          title
          image {
            id
          }
          createdAt
          postAt
          live
          canonicalUrl
          locked
          exportedAt
          lockedBy {
            id
            firstName
            lastName
          }
          workflow {
            id
          }
        }
      }
    }
  }
`;

export const SEARCH_ARTICLES_FEED = gql`
  query SearchArticlesFeed($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Article {
          id
          oid
          active
          legacyId
          title
          postAt
          provider
          createdAt
          sourceUrl
          teaser
          canonicalUrl
          pages {
            id
            oid
            body
          }
          notes
          taxNew: taxonomies(filterString: ["name = STATUS_NEW"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
          taxUpdated: taxonomies(filterString: ["name = STATUS_UPDATED"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
          taxDuplicate: taxonomies(filterString: ["name = STATUS_DUPLICATE"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
          taxCompared: taxonomies(filterString: ["title = Compared"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
          taxPublished: taxonomies(filterString: ["title = Published"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
          taxRejected: taxonomies(filterString: ["title = Rejected"]) {
            id
            title
            parent {
              id
              title
              name
              parent {
                id
                title
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_AUDIO = gql`
  query SearchAudio($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Audio {
          id
          oid
          title
        }
      }
    }
  }
`;

export const SEARCH_AUTHORS = gql`
  query SearchAuthors($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Author {
          id
          oid
          firstName
          lastName
          email
          canonicalUrl
          position
        }
      }
    }
  }
`;

export const SEARCH_CLASSIFIEDS = gql`
  query SearchClassifieds($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Classified {
          id
          oid
          title
          contactName
          live
          canonicalUrl
        }
      }
    }
  }
`;

export const SEARCH_COMMENTS = gql`
  query SearchComments($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Comment {
          id
          oid
          title
          createdAt
          updatedAt
          body
          active
          userName
          userEmail
          spam
          remoteAddr
          article: owner {
            ... on Article {
              id
              oid
              articleLabelTitle: title
            }
            ... on Product {
              id
              oid
              productLabelTitle: name
            }
            ... on Company {
              id
              oid
              companyLabelTitle: name
            }
            ... on Gallery {
              id
              oid
              galleryLabelTitle: title
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_COMPANIES = gql`
  query SearchCompanies($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Company {
          id
          oid
          title: name
        }
      }
    }
  }
`;

export const SEARCH_EDITIONS = gql`
  query SearchEditions($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Edition {
          id
          oid
          title
          postAt
          canonicalUrl
        }
      }
    }
  }
`;

export const SEARCH_EDITORIALS = gql`
  query SearchEditorials($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on EditorialContent {
          id
          oid
          title
          name
          linkTitle
          linkUrl
          live
          updatedAt
        }
      }
    }
  }
`;

export const SEARCH_EVENTS = gql`
  query SearchEvents($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Event {
          id
          oid
          title
          beginAt
          endAt
          live
          canonicalUrl
          active
          _attendantStats {
            count
          }
        }
      }
    }
  }
`;

export const SEARCH_FILES = gql`
  query SearchFiles($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on File {
          id
          oid
          title
        }
      }
    }
  }
`;

export const SEARCH_GALLERIES = gql`
  query SearchGalleries($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Gallery {
          id
          oid
          title
          live
        }
      }
    }
  }
`;

export const SEARCH_IMAGES = gql`
  query SearchImages($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Image {
          id
          oid
          title
          url
          absoluteUrl
          credit
          caption
          description
          height
          width
          remote
          updatedAt
          fileSize
          altText
        }
      }
    }
  }
`;

export const SEARCH_NEWSLETTERS = gql`
  query SearchNewsletters($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Newsletter {
          id
          oid
          title
        }
      }
    }
  }
`;

export const SEARCH_NEWSLETTER_ISSUES = gql`
  query SearchNewsletterIssues($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on NewsletterIssue {
          id
          oid
          title
          postAt
          canonicalUrl
        }
      }
    }
  }
`;

export const SEARCH_PAGES = gql`
  query SearchPages($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Page {
          id
          oid
          title
          url
          createdAt
          active
          updatedAt
          canonicalUrl
        }
      }
    }
  }
`;

export const SEARCH_POLLS = gql`
  query SearchPolls($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Poll {
          id
          oid
          title
          live
          active
          canonicalUrl
          createdAt
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Product {
          id
          oid
          title: name
          active
          productType
          internalId
          externalId
          canonicalUrl
        }
      }
    }
  }
`;

export const SEARCH_VARIATION_PRODUCTS = gql`
  query SearchVariationProducts($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Product {
          id
          oid
          title
          name
          active
          productType
          internalId
          externalId
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS_WITH_SUBSCRIPTIONS = gql`
  query SearchProductsWithSubscriptions($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Product {
          id
          oid
          title: name
          active
          productType
          internalId
          externalId
          updatedAt
          subscriptionProduct {
            id
            type
            licenseType
            resource
          }
        }
      }
    }
  }
`;

export const SEARCH_PUBLICATIONS = gql`
  query SearchPublications($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Publication {
          id
          oid
          title
        }
      }
    }
  }
`;

export const SEARCH_REDIRECTS = gql`
  query SearchRedirects($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Redirect {
          id
          oid
          actualUrl
          friendlyUrl
          live
          redirectType
          updatedAt
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query SearchUsers($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on User {
          id
          oid
          firstName
          lastName
          email
          city
          stateName
          countryName
          company
          userType
          createdAt
          lastLoginAt
        }
      }
    }
  }
`;

export const SEARCH_USERS_EXPORT = gql`
  query SearchUsersExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on User {
          id
          oid
          prefix
          firstName
          middleName
          lastName
          suffix
          userType
          company
          jobTitle
          createdAt
          updatedAt
          lastLoginAt
          email
          ipRanges {
            beginIpAddress
            endIpAddress
          }
          address1
          address2
          city
          stateName
          countryName
          postalCode
          homePhone
          workPhone
          cellPhone
          fax
          notes
          legacyId
          topics {
            title
          }
          newsletters {
            title
            externalId
          }
          customFields {
            id
            optionSelectionPath
            value
            type {
              id
              name
              defaultValue
            }
          }
          subscriptions {
            id
            oid
            live
            beginAt
            endAt
            renewal
            recurring
            createdAt
            externalId
            type
            status
            trial
            name
            resource
            product {
              id
              name
              subscriptionProduct {
                id
                publication {
                  id
                }
                newsletter {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_USERS_TOKEN_EXPORT = gql`
  query SearchUsersTokenExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $expiresAt: Time!) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on User {
          id
          oid
          multipubId
          firstName
          lastName
          email
          authToken(expiresAt: $expiresAt)
        }
      }
    }
  }
`;

export const SEARCH_VIDEOS = gql`
  query SearchVideos($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Video {
          id
          oid
          title
          url
          remote
        }
      }
    }
  }
`;

export const SEARCH_DIRECTORY = gql`
  query SearchDirectory($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }
    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Company {
          id
          oid
          name
          lastViewedAt
          updatedAt
          contact
          active
          stateName
          canonicalUrl
          country {
            id
            name
            isoName
          }
          state {
            id
            name
            country {
              isoName
            }
          }
          topics {
            id
            title
          }
        }
      }
    }
  }
`;

export const SEARCH_OFFERS = gql`
  query SearchOffers($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }
    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Offer {
          id
          oid
          name
          code
          endAt
          startAt
        }
      }
    }
  }
`;

export const SEARCH_SETTINGS = gql`
  query SearchSettings($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }
    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Setting {
          id
          oid
          name
          stringValue
          description
        }
      }
    }
  }
`;

export const SEARCH_TAXONOMIES = gql`
  query SearchTaxonomies($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Taxonomy {
          id
          name
          title
          active
          allowSelect:selectable
          _childStats{
            count
          }
          parent{
            id
            title
          }
        }
      }
    }
  }
`

export const SEARCH_ORDERS = gql`
  query SearchOrders($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }
    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Order {
          id
          oid
          lastName
          firstName
          email
          createdAt
          submittedAt
          amountPaid
          trackingCode
          status
        }
      }
    }
  }
`;

export const SEARCH_EXPORT_ORDERS = gql`
  query SearchExportOrders($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }
    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Order {
          id
          oid
          lastName
          firstName
          email
          createdAt
          amountPaid
          phone
          note
          user {
            oid
            legacyId
          }
          address1
          address2
          company
          city
          country {
            iso
          }
          postalCode
          province
          referralCode
          status
          items(productExists: true) {
            id
            product {
              name
              oid
              internalId
              externalId
              weight
              weightUnit
              productType
              bundledProducts {
                oid
                id
                title
                name
                weight
                internalId
                externalId
                weightUnit
              }
            }
            type
            quantity
            cost
            salesTax
          }
          shippingAddress {
            lastName
            firstName
            address1
            address2
            country
            postalCode
            phone
            company
            state
            email
            province
            city
          }
          payment {
            cardNumberLastFourDigits
            transactionId
          }
          state {
            id
            name
          }
          offers {
            id
            name
            code
          }
          shippingType
          totalShippingCost
          totalSalesTax
        }
      }
    }
  }
`;

export const SEARCH_MEDIA = gql`
  query SearchMedia($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Video {
          id
          oid
          title
          url
          absoluteUrl
          externalId
          image {
            id
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
          remote
          updatedAt
          fileSize
        }
        ... on Image {
          id
          oid
          title
          url
          absoluteUrl
          credit
          caption
          description
          height
          width
          remote
          updatedAt
          fileSize
          altText
        }
        ... on File {
          id
          oid
          title
          url
          absoluteUrl
          remote
          updatedAt
          fileSize
          image {
            id
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
        }
        ... on Audio {
          id
          oid
          title
          url
          absoluteUrl
          image {
            id
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
          remote
          updatedAt
          fileSize
        }
        ... on Gallery {
          id
          oid
          title
          galleryItems {
            id
            image {
              id
              absoluteUrl
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_FILE_RECORDS = gql`
  query SearchFileRecords($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on File {
          id
          oid
          title
          absoluteUrl
          _fileDownloadStats(filterString: $filterString) {
            count
          }
          fileDownloads(filterString: $filterString) {
            id
            downloadedAt
          }
        }
      }
    }
  }
`;

export const SEARCH_FILE_RECORDS_EXPORT = gql`
  query SearchFileRecordsExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str], $downloadPage: Integer, $downloadPerPage: Integer) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on File {
          id
          oid
          title
          url
          absoluteUrl
          taxonomies {
            id
            title
          }
          _fileDownloadStats(filterString: $filterString) {
            count
          }
          fileDownloads(filterString: $filterString, perPage: $downloadPerPage, page: $downloadPage) {
            id
            downloadedAt
            user {
              oid
              id
              email
              firstName
              lastName
              company
              jobTitle
              address1
              address2
              city
              stateName
              province
              countryName
              postalCode
              defaultPhone
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_AUDIO_RECORDS = gql`
  query SearchAudioRecords($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Audio {
          id
          oid
          title
          absoluteUrl
          _fileDownloadStats(filterString: $filterString) {
            count
          }
        }
      }
    }
  }
`;

export const SEARCH_AUDIO_RECORDS_EXPORT = gql`
  query SearchAudioRecordsExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str], $downloadPage: Integer, $downloadPerPage: Integer) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Audio {
          id
          oid
          title
          absoluteUrl
          taxonomies {
            id
            title
          }
          _fileDownloadStats(filterString: $filterString) {
            count
          }
          fileDownloads(filterString: $filterString, perPage: $downloadPerPage, page: $downloadPage) {
            id
            downloadedAt
            user {
              oid
              id
              email
              firstName
              lastName
              company
              jobTitle
              address1
              address2
              city
              stateName
              province
              countryName
              postalCode
              defaultPhone
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_VIDEO_RECORDS = gql`
  query SearchVideoRecords($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Video {
          id
          oid
          title
          absoluteUrl
          createdAt
          updatedAt
          _fileDownloadStats(filterString: $filterString) {
            count
          }
        }
      }
    }
  }
`;

export const SEARCH_VIDEO_RECORDS_EXPORT = gql`
  query SearchVideoRecordsExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str], $filterString: [Str], $downloadPage: Integer, $downloadPerPage: Integer) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Video {
          id
          oid
          title
          absoluteUrl
          taxonomies {
            id
            title
          }
          _fileDownloadStats(filterString: $filterString) {
            count
          }
          fileDownloads(filterString: $filterString, perPage: $downloadPerPage, page: $downloadPage) {
            id
            downloadedAt
            user {
              oid
              id
              email
              firstName
              lastName
              company
              jobTitle
              address1
              address2
              city
              stateName
              province
              countryName
              postalCode
              defaultPhone
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_MEDIA_IMAGE_EXPORT = gql`
  query SearchMediaImageExport($itemTypes: [SearchableTypeEnum], $perPage: Integer!, $page: Integer!, $liveOnly: Bool, $sortStrings: [Str], $filterStrings: [Str], $terms: [Str]) {
    _searchStats(itemTypes: $itemTypes, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly) {
      count
    }

    search(itemTypes: $itemTypes, sortStrings: $sortStrings, filterStrings: $filterStrings, terms: $terms, liveOnly: $liveOnly, perPage: $perPage, page: $page) {
      itemType
      item {
        ... on Image {
          id
          oid
          title
          url
          createdAt
          updatedAt
          height
          width
          fileSize
          altText
          credit
          caption
          searchable
          description
          topics {
            oid
            id
            title
          }
          sitePlacements {
            oid
            id
            title
          }
          classifications {
            oid
            id
            title
          }
          taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
            oid
            id
            title
          }
          articles {
            id
            title
          }
          products {
            id
            name
          }
          events {
            id
            title
          }
          companies {
            id
            name
          }
          taxonomiesByMain {
            id
            title
          }
          galleries {
            id
            title
          }
          blogs {
            id
            title
          }
          editions {
            id
            title
          }
          authors {
            id
            fullName
          }
          classifieds {
            id
            title
          }
          newsletterIssues {
            id
            title
          }
        }
      }
    }
  }
`;

// TODO: Move off of Apollo Boost to have support for hueristic fragment matching. This will
// suppress the warning. Will it fix the issue with causing an empty data object on Query?
// start here? https://medium.com/commutatus/whats-going-on-with-the-heuristic-fragment-matcher-in-graphql-apollo-client-e721075e92be
// TODO: Then we could move to using a single search query.

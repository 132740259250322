import gql from 'graphql-tag';
import { RELATED_TYPES } from 'constants/index';
import pluralize from 'pluralize';

export const ARTICLES_QUERY = gql`
  query ArticlesQuery($perPage: Integer!, $page: Integer) {
    _articleStats {
      count
    }

    articles(perPage: $perPage, page: $page, sortString: "updatedAt desc") {
      oid
      id
      title
      createdAt
      postAt
      updatedAt
      workflow {
        latestEdit {
          id
          currentStep {
            id
            name
          }
        }
      }
    }
  }
`

export const DEFAULT_ARTICLE = gql`
  query DefaultArticle {
    managers @client {
      article {
        edit {
          defaultArticleDOM
        }
      }
    }
  }
`;

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

// TODO: How to handle paginating rrleated content within edit.
const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'relatedArticles',
    'events',
    'classifieds',
    'companies',
    'products',
    'galleries',
    'polls',
    'publications',
    'files',
    'relatedProducts',
  ];

  // Filter to show just the "pick" if pick is presentt, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);
    let title = 'title';

    if (q === 'companies' || q === 'relatedProducts') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const ARTICLE_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query ArticleEditRelatedArticles($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      article(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
          pick: related_Item.query,
          vars: 'page: $page, perPage: $perPage',
        })}
      }
    }`;
}

export const ARTICLE_EDIT_RELATED_ARTICLES = gql`
  query ArticleEditRelatedArticles($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    _relatedArticleStats {
      count
    }

    article(oid: $oid) {
      ${relatedContentFragments({
  pick: 'relatedArticles',
  vars: 'page: $page, perPage: $perPage',
})}
    }
  }
`;

// NOTE: canonicalUrl was removed as it wouldnt pass validation
// ToolError: Validation of GraphQL query document failed
// Cannot query field "canonicalUrl" on type "Meta".
export const ARTICLE_EDIT = gql`
  query ArticleEdit($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    article(oid: $oid) {
      oid
      id
      title
      subTitle
      kicker
      body
      active
      canonicalPath
      notes
      endnotes
      locked
      template {
        id
        oid
        title
      }
      templated {
        id
        oid
        title
      }
      lockedBy {
        firstName
        lastName
      }
      pages {
        id
        oid
        body
        footnotes
      }
      locked
      lockedBy {
        id
        firstName
        lastName
      }
      blog {
        oid
        id
        title
      }
      teaser
      image {
        id
        oid
        height
        width
        fileSize
        url
        absoluteUrl
        altText
      }
      live
      searchable
      source
      sourceUrl
      allowComments
      createdAt
      postAt
      pullAt
      publicationAt
      dueAt
      directAccess
      legacyId
      authors {
        id
        oid
        firstName
        lastName
      }
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
          maximumValues
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      newsletterIssues {
        id
        title
        newsletter {
          id
          title
        }
      }
      editionArticles {
        id
        edition {
          id
          title
          publication {
            id
            title
          }
        }
        section {
          id
          title
        }
      }
      taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      taxonomies {
        id
        title
      }
      keywords {
        id
        oid
        tag
      }
      blogCategories {
        id
        title
      }
      products {
        id
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
          description
        }
      }
      meta {
        id
        slug
        browserTitle
        keywords
        description
        canonicalUrl
        bottomText
        headers {
          id
          name
          value
        }
      }
      _commentStats {
        count
      }
      audios {
        id
        absoluteUrl
      }
      videos {
        id
        absoluteUrl
      }
      images {
        id
        absoluteUrl
      }
      files {
        id
        absoluteUrl
      }
      ${relatedContentFragments()}
    }
  }
`;

export const ARTICLE_EDIT_CUSTOM_FIELDS = gql`
  query ArticleEditCustomFields ($id: IDType!, $perPage:Integer!, $page:Integer!) {
    article(id: $id) {
      id
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
          maximumValues
        }
      }
    }
  }
`;

export const ARTICLE_EDIT_COMPARE = gql`
  query ArticleEditCompare($oid: Integer!) {
    article(oid: $oid) {
      oid
      id
      title
      kicker
      body
      active
      canonicalPath
      postAt
      teaser
      notes
      endnotes
      pages {
        id
        oid
        body
      }
      authors {
        id
        oid
        firstName
        lastName
      }
      customFields {
        id
        value
        optionSelectionPath
        type {
          id
          name
          maximumValues
        }
      }
      sitePlacements {
        id
        title
      }
      classifications {
        id
        title
      }
      topics {
        id
        title
      }
    }
  }
`;

export const ARTICLE_EDIT_COMMENTS = gql`
  query ArticleEditComments($oid: Integer!, $filterString: [Str!]) {
    article(oid: $oid) {
      id

      _commentStats(filterString: $filterString) {
        count
      }

      comments(filterString: $filterString) {
        id
        oid
        createdAt
        title
        body
        userEmail
        userName
        userAgent
        remoteAddr
        offensive
        spam
        treePath
        active
        user {
          id
          oid
          email
        }
      }
    }
  }
`

const articleEditVersionsFragments = () => (
  `
    id
    oid
    title
    subTitle
    teaser
    postAt
    pullAt
    updatedAt
    endnotes
    notes
  `
);

// UNCOMMENT ME WHEN QUERY IS FIXED
export const ARTICLE_EDIT_VERSIONS = gql`
  query ArticleEditVersion($oid: Integer!, $time: Time!){
    article(oid: $oid) {
      ${articleEditVersionsFragments()}
      _versionStats { count }
      versions(perPage: 2, filterString:["updatedAt <= $time"]) {
        ${articleEditVersionsFragments()}
        byUser {
          id
          firstName
          lastName
        }
        article { id title }
        pages {
          id
          body
          sortingOrder
          updatedAt
          footnotes
        }
       }
      versionTimes
      versionAt(time: $time) {
        ${articleEditVersionsFragments()}
        article { id title }
        pages {
          id
          body
          sortingOrder
          updatedAt
          footnotes
        }
       }
      pages {
        id
        body
        sortingOrder
        updatedAt
        footnotes
      }
    }
  }
`;

export const TAXONOMY_ARTICLES = gql`
  query TaxonomyArticles ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _articleStats {
        count
      }
      articles (perPage: $perPage, page: $page)  {
        id
        oid
        title
      }
    }
  }
 `;

export const ARTICLE_EDIT_MUTATION = gql`
  mutation ArticleUpdate($input: ArticleUpdateInput!) {
    articleUpdate(input: $input) {
      articles {
        id
        oid
        image {
          id
          oid
          width
          height
          fileSize
          url
          absoluteUrl
          altText
        }
      }
      success
      errors
    }
  }
`;

export const ARTICLE_SET_KEYWORDS_MUTATION = gql`
  mutation ArticleSetKeywords ($input: ArticleSetKeywordsInput!) {
    articleSetKeywords(input: $input) {
      success
      errors
    }
  }
`;

export const ARTICLE_ADD_KEYWORDS = gql`
  mutation ArticleAddKeywords ($input: ArticleAddKeywordsInput!) {
    articleAddKeywords(input: $input) {
      success
      errors
    }
  }
`;

export const ARTICLE_REMOVE_KEYWORDS = gql`
  mutation ArticleRemoveKeywords ($input: ArticleRemoveKeywordsInput!) {
    articleRemoveKeywords(input: $input) {
      success
      errors
    }
  }
`;

export const ARTICLE_SET_AUTHORS = gql`
  mutation ArticleSetAuthors ($input: ArticleSetAuthorsInput!) {
    articleSetAuthors(input: $input){
      success
      errors
    }
  }
`;

export const ARTICLE_SET_PRODUCTS = gql`
  mutation ArticleSetProducts ($input: ArticleSetProductsInput!) {
    articleSetProducts(input: $input){
      success
      errors
    }
  }
`;

export const ARTICLE_PAGE_CREATE = gql`
  mutation ArticlePageCreate($input: ArticlePageCreateInput!) {
    articlePageCreate(input: $input) {
      articlePage {
        article {
          id
        }
        body
        footnotes
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ARTICLE_PAGE_UPDATE = gql`
  mutation ArticlePageUpdate($input: ArticlePageUpdateInput!) {
    articlePageUpdate(input: $input) {
      articlePages {
        article {
          id
        }
        body
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ARTICLE_PAGE_DELETE = gql`
  mutation ArticlePageDelete($input: ArticlePageDeleteInput!) {
    articlePageDelete(input: $input) {
      articlePages {
        article {
          id
        }
        body
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ARTICLE_NEWSLETTER = gql`
  query ArticleNewsletter($oid: Integer!) {
    article(oid: $oid) {
      title
      body
      kicker
      sponsor {
        id
        title
      }
      authors {
        firstName
        lastName
      }
    }
  }
`;

export const ARTICLE_CREATE = gql`
  mutation ArticleCreate($input: ArticleCreateInput!) {
    articleCreate(input: $input) {
      article {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ARTICLE_DELETE = gql`
  mutation ArticleDelete($input: ArticleDeleteInput!) {
    articleDelete(input: $input) {
      articles {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ARTICLE_ADD_TAXONOMIES = gql`
  mutation ArticleAddTaxonomies ($input: ArticleAddTaxonomiesInput!) {
    articleAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const ARTICLE_REMOVE_TAXONOMIES = gql`
  mutation ArticleRemoveTaxonomies ($input: ArticleRemoveTaxonomiesInput!) {
    articleRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const ARTICLE_SET_TAXONOMIES = gql`
  mutation ArticleSetTaxonomies($input: ArticleSetTaxonomiesInput!) {
    articleSetTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const ARTICLE_PAGES = gql`
  query ArticlePages($id: IDType) {
    article(id: $id) {
    id
    pages {
      id
      oid
      body
    }
  }
}
`;

export const ARTICLE_COPY_TO_SITES = gql`
  mutation ArticleCopyToSites($input: ArticleCopyToSitesInput!) {
    articleCopyToSites(input: $input) {
      success
      errors
    }
  }
`;

export const ARTICLE_PUBLICATION = gql`
  query ArticlePublication($id: IDType!) {
    article(id: $id) {
      id
      editionItems {
        id
        printPage {
          id
          name
        }
        edition {
          id
          title
          publication
          {
            id
            title
          }
        }
        department: section {
          id
          title
        }
      }
  }
}
`;
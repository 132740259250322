import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

export const userData = () => {
  return `
  id
  oid
  prefix
  firstName
  middleName
  lastName
  suffix
  userType
  company
  jobTitle
  createdAt
  updatedAt
  lastLoginAt
  email
  ipRanges {
    beginIpAddress
    endIpAddress
  }
  address1
  address2
  city
  stateName
  province
  countryName
  postalCode
  homePhone
  workPhone
  cellPhone
  fax
  notes
  legacyId
  topics {
    title
  }
  newsletters {
    title
    externalId
  }
  customFields {
    id
    optionSelectionPath
    value
    type {
      id
      name
      defaultValue
    }
  }
  subscriptions {
    id
    oid
    live
    beginAt
    endAt
    renewal
    recurring
    createdAt
    externalId
    type
    status
    trial
    name
    resource
    product {
      id
      name
      subscriptionProduct {
        id
        publication {
          id
        }
        newsletter {
          id
        }
      }
    }
    orderItem {
      id
      product {
        id
        name
      }
    }
  }
  `;
}

export const generateTokensUserData = (expiresAt = '') => {
  return `
    id
    oid
    multipubId
    firstName
    lastName
    email
    authToken(expiresAt: "${expiresAt}")
  `;
}

export const USERS = gql`
  query Users($perPage: Integer!, $page: Integer) {
    _userStats {
      count
    }

    users(perPage: $perPage, page: $page, sortString: "createdAt desc") {
      id
      oid
      firstName
      lastName
      email
      city
      stateName
      province
      countryName
      company
      userType
      subscriptions {
        id
        oid
        live
        beginAt
        endAt
        renewal
        recurring
        createdAt
        externalId
        type
        orderItem {
          id
          product {
            name
          }
        }
      }
    }
  }
`;



type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

// TODO: How to handle paginating rrleated content within edit.
const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'companies',
  ];

  // Filter to show just the "pick" if pick is presentt, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);
    let title = 'title';

    if (q === 'companies') {
      title = 'title:name';
    }
    else if (q === 'pages') {
      title = 'title:body';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};


export const USER_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query UserEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      user(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
    pick: related_Item.query,
    vars: 'page: $page, perPage: $perPage',
  })}
      }
    }`;
}

export const USER_EDIT_SUBSCRIPTION = gql`
  query UserEditSubcription($oid: Integer!) {
    user(oid: $oid) {
      id
      subscriptions {
        id
        oid
        live
        beginAt
        resource
        endAt
        renewal
        recurring
        externalId
        licenseType
        trial
        type
        status
        name
        item {
          __typename
          ... on Newsletter {
            id
          }
          ... on Publication {
            id
          }
        }
        product{
          id
          oid
          name
        }
      }
    }
  }
`;

export const USER_EDIT = gql`
  query UserEdit($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    user(oid: $oid) {
      id
      oid
      token
      prefix
      active
      userType
      firstName
      middleName
      lastName
      suffix
      email
      externalId
      multipubId
      company
      companyContact
      jobTitle
      cellPhone
      workPhone
      homePhone
      fax
      address1
      address2
      city
      postalCode
      stateName
      countryName
      province
      password
      notes
      orders(sortString:["submittedAt desc"]) {
        id
        oid
        lastName
        firstName
        email
        createdAt
        submittedAt
        amountPaid
      }
      ipRanges {
        id
        oid
        beginIpAddress
        endIpAddress
      }
      subscriptions {
        id
        oid
        live
        beginAt
        resource
        endAt
        renewal
        recurring
        externalId
        licenseType
        trial
        type
        status
        name
        item {
          __typename
          ... on Newsletter {
            id
          }
          ... on Publication {
            id
          }
        }
      }
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
      classifications {
        id
        title
      }
      privileges(perPage: 100) {
        id
        oid
        displayName
        name
      }
      roles {
        id
        oid
        displayName
        name
      }
      _ipRangeStats {
        count
      }
      giftees {
        id
        oid
        firstName
        middleName
        lastName
        email
        city
        stateName
        countryName
        province
        createdAt
      }
      ${relatedContentFragments()}
    }
  }
`;

export const USER_EDIT_CUSTOM_FIELDS = gql`
  query UserEditCustomFields ($id: IDType!, $perPage:Integer!, $page:Integer) {
    user(id: $id) {
      id
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UserUpdate ($input: UserUpdateInput!) {
    userUpdate(input: $input)
    {
      users {
        id
      }
      success
      errors
    }
  }
`;

export const USER_CREATE = gql`
  mutation UserCreate ($input: UserCreateInput!) {
    userCreate(input: $input)
    {
      user {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const USER_DELETE = gql`
  mutation UserDelete ($input: UserDeleteInput!) {
    userDelete(input: $input)
    {
      success
      errors
      users { id }
    }
  }
`;

export const USER_IP_RANGE_UPDATE = gql`
  mutation UserIpRangeUpdate ($input: UserIpRangeUpdateInput!) {
    userIpRangeUpdate(input: $input)
    {
      userIpRanges {
        id
        oid
        beginIpAddress
        endIpAddress
      }
      success
      errors
    }
  }
`;

export const USER_IP_RANGE_CREATE = gql`
  mutation UserIpRangeCreate ($input: UserIpRangeCreateInput!) {
    userIpRangeCreate(input: $input)
    {
      userIpRange {
        id
        oid
        beginIpAddress
        endIpAddress
      }
      success
      errors
    }
  }
`;

export const USER_IP_RANGE_DELETE = gql`
  mutation UserIpRangeDelete ($input: UserIpRangeDeleteInput!) {
    userIpRangeDelete(input: $input)
    {
      userIpRanges {
        id
        oid
        beginIpAddress
        endIpAddress
      }
      success
      errors
    }
  }
`;

export const USER_SET_COMPANIES = gql`
  mutation UserSetCompanies ($input: UserSetCompaniesInput!) {
    userSetCompanies(input: $input)
    {
      success
      errors
    }
  }
`;

export const USER_ADD_TAXONOMIES = gql`
  mutation UserAddTaxonomies ($input: UserAddTaxonomiesInput!) {
    userAddTaxonomies(input: $input) {
      taxonomies { id }
      user { id }
      success
      errors
    }
  }
`;

export const USER_REMOVE_TAXONOMIES = gql`
  mutation UserRemoveTaxonomies ($input: UserRemoveTaxonomiesInput!) {
    userRemoveTaxonomies(input: $input) {
      taxonomies { id }
      user { id }
      success
      errors
    }
  }
`;

export const USER_ADD_NEWSLETTERS = gql`
  mutation UserAddNewsletters ($input: UserAddNewslettersInput!) {
    userAddNewsletters(input: $input) {
      newsletters { id }
      user { id }
      success
      errors
    }
  }
`;

export const USER_REMOVE_NEWSLETTERS = gql`
  mutation UserRemoveNewsletters ($input: UserRemoveNewslettersInput!) {
    userRemoveNewsletters(input: $input) {
      newsletters { id }
      user { id }
      success
      errors
    }
  }
`;

export const USER_SET_PRIVILEGES = gql`
  mutation UserSetPrivileges ($input: UserSetPrivilegesInput!) {
    userSetPrivileges(input: $input)
    {
      success
      errors
    }
  }
`;

export const USER_SET_ROLES = gql`
  mutation UserSetRoles ($input: UserSetRolesInput!) {
    userSetRoles(input: $input)
    {
      success
      errors
    }
  }
`;

export const USER_AUTH_ROLES_PRIVILEGES = gql`
  query UserAuthRolesPrivileges {
    context {
      user {
        id
        privileges(perPage: 100) {
          id
          name
        }
        allPrivileges(perPage: 100) {
          id
          name
        }
      }
    }
  }
`;

export const SELECTED_USER = (ids: Array<string>, variables: {}, param: string, counter: number, query: string, expiresAt: string) => {
  for (let index = 0; index < ids.length; index++) {
    let key = `user${counter}`;
    let value = ids[counter];
    variables[key] = value;
    if (param != '') param += ',';
    param += "$" + key + ":IDType";

    if(expiresAt !== '') {
      query += `user${counter}: user(id: $${key} ) {
        ${generateTokensUserData(expiresAt)}
      }`
    } else {
      query += `user${counter}: user(id: $${key} ) {
        ${userData()}
      }`
    }
    counter++;
  }
  return { query, variables, param, counter }
};

export const USERS_SELECT_ALL = gql`
  query UsersSelectAll ($perPage:Integer!, $page:Integer!) {

    _userStats {
      count
    }

    users(perPage: $perPage, page: $page, sortString:["lastName asc"]) {
      oid
      id
      firstName
      lastName
      company
      userType
    }
  }
`;

export const LOGGEDIN_USER = gql`
  query LoggedinUser {
    context {
      tokenExpiresAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`


export const TEMPORARY_LOGIN_TOKEN = gql`
  query TemporaryLoginToken {
    context { threeMinuteToken }
  }
`;

export const USER_ACTIVITY = gql`
  query UserActivity($email: Email, $startAt: Time, $filterString: [Str], $endAt: Time, $page: Integer ) {
    user(email: $email) {
      id
      oid
      firstName
      lastName
      _fileDownloadStats(filterString:$filterString) {
        count
      }
      _viewItemStats(startAt: $startAt, endAt: $endAt) {
        count
      }
      viewItems(startAt: $startAt, endAt: $endAt, page:  $page, perPage: 100) {
        view {
          createdAt
          postData
          remoteIp
          url
          session {
            userAgent
          }
        }
        item {
          ...on Article {
            id
            oid
            viewCount
            title
            __typename
          }
          ...on Product {
            id
            oid
            viewCount
            title: name
            __typename
          }
          ...on Company {
            id
            oid
            viewCount
            title: name
            __typename
          }
        }
      }
      fileDownloads(filterString:$filterString, page:  $page, perPage: 100) {
        id
        fileType
        file {
          id
          title
        }
        downloadedAt
        userAgent
        remoteIp
        url
      }
    }
  }
`;

export const USER_NEWSLETTER_SUBSCRIPTIONS = gql`
  query userNewsletterSubscriptions($id: IDType!) {
    user(id: $id) {
      id
      newsletters {
        id
      }
      subscriptions {
        item {
          ...on Newsletter {
            id
          }
        }
      }
    }
  }
`;

export const USER_ARTICLE_EDITS = gql`
  query UserArticleEdits($id: IDType!, $filterString: [Str!], $sortString: [Str!]) {
    user(id: $id) {
      id
      oid
      email
      _editedArticleVersionStats(filterString: $filterString) {
        count
      }
      _editedArticlePageVersionStats(filterString: $filterString) {
        count
      }
      editedArticleVersions(filterString: $filterString, sortString: $sortString) {
        updatedAt
        article {
          id
          oid
          title
          createdAt
          updatedAt
          postAt
          live
          locked
        }
      }
      editedArticlePageVersions(filterString: $filterString, sortString: $sortString) {
        updatedAt
        page {
          oid
          article {
            id
            oid
            title
            image {
              id
            }
            createdAt
            updatedAt
            postAt
            live
            locked
          }
        }
      }
    }
  }
`;

export const USER_IP_RANGES = gql`
  query UserIpRanges($userIds: [IDType], $page: Integer!, $perPage: Integer!, $sortString: [Str] ) {
    userIpRanges(userIds: $userIds, page: $page, perPage: $perPage, sortString: $sortString) {
      id
      oid
      beginIpAddress
      endIpAddress
    }
  }
`;